'use es6';

import set from 'transmute/set';
import { CHANNEL_INSTANCE_ID, GENERIC_CHANNEL_ID, INTEGRATION_ID, RECIPIENTS, SENDERS } from '../constants/keyPaths';
/**
 * The modern home of common message operators as we
 * look to get off of ./commonMessageFormatSetters, which
 * has a lot of edge-casing and checks introduced during
 * record migrations.
 */

export var setChannelInstanceId = set(CHANNEL_INSTANCE_ID);
export var setGenericChannelId = set(GENERIC_CHANNEL_ID);
export var setIntegrationId = set(INTEGRATION_ID);
export var setRecipients = set(RECIPIENTS);
export var setSenders = set(SENDERS);