if (window.performance && typeof window.performance.mark === 'function') {
  window.performance.mark('mark_i18n_load_start');
}


"use strict";
'use es6';

window.I18n = window.I18n || {}; // isNewLoaderFlag used in Baldric to ensure I18n was imported before Baldric

I18n.isNewLoader = true;





